export default [
  {
    path: "/cms-drivers",
    name: "cms-drivers",
    meta: {
      requiresAuth: true,
      permission: "",
      title: "Ridery Conductores",
      module: "cms-drivers",
      layout: "admin",
      adminLayoutNav: true,
      icon: "mdi-car-side",
    },
    component: () =>
      import(
        "@/views/cms-drivers/CMSDriversLayout.vue"
      ),
    children: [
      {
        path: "categories",
        name: "CMSDrivers-category-index",
        meta: {
          adminLayoutNav: true,
          permission: "cms-drivers/categories",
          requiresAuth: true,
          layout: "admin",
          title: "Categorías",
          module: "categories",
          icon: "mdi-format-list-bulleted-type",
        },
        component: () =>
          import(
            "@/views/cms-drivers/CMSDriversCategoriesList.vue"
          ),
      },
      {
        path: "allies",
        name: "CMSDrivers-ally-index",
        meta: {
          adminLayoutNav: true,
          permission: "cms-drivers/allies",
          requiresAuth: true,
          layout: "admin",
          title: "Aliados",
          module: "allies",
          icon: "mdi-human-greeting",
        },
        component: () =>
          import(
            "@/views/cms-drivers/CMSDriversAlliesList.vue"
          ),
      },
      {
        path: "promotions",
        name: "CMSDrivers-promotion-index",
        meta: {
          adminLayoutNav: true,
          permission: "cms-drivers/promotions",
          requiresAuth: true,
          layout: "admin",
          title: "Promociones",
          module: "promotions",
          icon: "mdi-ticket",
        },
        component: () =>
          import(
            "@/views/cms-drivers/CMSDriversPromotionsList.vue"
          ),
      },
    ],
  },
];
