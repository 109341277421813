import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  required,
  email,
  length,
  min,
  min_value,
  max,
  max_value,
  size,
  confirmed,
  ext,
  numeric,
  alpha,
  alpha_dash,
  alpha_spaces,
  digits,
  regex,
  alpha_num
} from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import en from 'vee-validate/dist/locale/en.json';

// Add rules
extend('required', required);
extend('email', email);
extend('length', length);
extend('min_value', min_value);
extend('min', min);
extend('max_value', max_value);
extend('max', max);
extend('ext', ext);
extend('size', size);
extend('numeric', numeric);
extend('confirmed', confirmed);
extend('alpha', alpha);
extend('alpha_spaces', alpha_spaces);
extend('digits', digits);
extend('regex', regex);
extend('alpha_dash', alpha_dash);
extend('alpha_num', alpha_num);
extend('https_url', {
  validate(value) {
    return /^https:\/\/.+$/.test(value);
  },
  message: 'La URL debe comenzar con "https://".'
});
extend('safe_chars', {
  validate(value) {
    return /^[A-Za-z0-9!_.,\-?:ñÑáéíóú$() ]*$/.test(value) && /\S/.test(value);
  },
  message:
    'La entrada debe contener al menos un carácter no vacío y solo caracteres permitidos: alfanuméricos, signos de exclamación, guiones, espacios y algunos caracteres seguros.'
});

extend('not_zero', {
  validate(value) {
    return parseFloat(value) !== 0;
  },
  message: 'El valor no puede ser 0.'
});

localize('es', es);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
